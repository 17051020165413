import React from 'react';
import { MainSubStructure, NormalProgrammeComponent, singleUploadObs } from '../../utils/index';
import CategoryManagementModel from './model';
import styles from './index.less';
import type { GetProp, UploadProps } from 'antd';
import { Form, Input, InputNumber, message, Modal, Select, Tabs, Upload } from 'antd';
import { observer } from 'mobx-react';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import compressImgOrVideo from '../../utils/compress';
import ImgDelte from '../../compnents/imgDelete';

const OBS_PATH = 'category/share/image/';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('仅支持上传JPG、PNG!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('图片必须小于5MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};

// 上传分享图
const shareImgUploadFormItem = (uploadLoading: boolean, image?: string, setFieldValue?: any) => (
  <Form.Item
    validateTrigger="onBlur"
    getValueFromEvent={() => image}
    name="image"
  >
    <ImgCrop
      showReset
      rotationSlider={false}
      aspect={424 / 340}
    >
      <Upload
        name="image"
        listType="picture-card"
        maxCount={1}
        showUploadList={false}
        customRequest={async (i: any) => {
          const unit = i.file.name.split(',');
          const file: any = await compressImgOrVideo(i.file);
          if (file === false) {
            return;
          }
          const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
          setFieldValue('image', url);
        }}
        beforeUpload={beforeUpload}
      >
        {image && typeof image === 'string' ? (
          <ImgDelte onDelte={() => setFieldValue('image', '')}>
            <img
              src={image}
              alt="sharePic"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </ImgDelte>
        ) : (
          <button
            style={{
              border: 0,
              background: 'none',
            }}
            type="button"
          >
            {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传</div>
          </button>
        )}
      </Upload>
    </ImgCrop>
  </Form.Item>
);

@observer
export default class CategoryManagement extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new CategoryManagementModel();
  }

  public declare store: CategoryManagementModel;

  render() {
    const { mainSubStructureModel, formRefTwo, mainSubStructureModelTwo, categoryFirstList, uploadLoading, setSecondLevelModalOpen, onSecondLevelSubmit, secondLevelModalOpen, onSubmit, setModalOpen, modalOpen, formRef } = this.store;
    return (
      <div className={styles.page}>
        <Tabs
          className={styles.tab}
          items={[
            {
              label: '一级类别',
              key: '1',
              children: <MainSubStructure store={mainSubStructureModel} />,
            },
            {
              label: '二级类别',
              key: '2',
              children: <MainSubStructure store={mainSubStructureModelTwo} />,
            },
          ]}
        />

        <Form
          scrollToFirstError
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Modal
            open={modalOpen}
            onCancel={setModalOpen}
            onOk={onSubmit}
          >
            <div className={styles.modalContent}>
              <Form.Item
                label="排序"
                name="categorySequence"
                extra="*数字越小展示位置越靠前"
                rules={[{ required: modalOpen }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={9999}
                />
              </Form.Item>
              <Form.Item
                label="类别"
                name="categoryName"
                extra="*4个汉字长度"
                rules={[{ required: modalOpen }]}
              >
                <Input
                  autoComplete="off"
                  maxLength={4}
                />
              </Form.Item>
              <Form.Item
                label="唯一值"
                name="categoryUniqueValue"
                hidden
              >
                <Input
                  autoComplete="off"
                  maxLength={20}
                />
              </Form.Item>
              <Form.Item
                label="关联二级"
                name={['children']}
                rules={[{ required: modalOpen }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount="responsive"
                  showSearch
                  optionFilterProp="label"
                  options={categoryFirstList}
                />
              </Form.Item>
              <Form.Item
                label="分享标题"
                name="shareTitle"
              >
                <Input
                  autoComplete="off"
                  maxLength={15}
                  showCount
                />
              </Form.Item>
              <Form.Item
                label="分享图"
                shouldUpdate
                extra="*支持JPG、PNG，大小不超过5M （标准尺寸规范：424*340）"
              >
                {({ getFieldValue, setFieldValue }) => {
                  const image = getFieldValue('image');
                  return shareImgUploadFormItem(uploadLoading, image, setFieldValue);
                }}
              </Form.Item>
            </div>
          </Modal>
        </Form>
        <Form
          scrollToFirstError
          ref={formRefTwo}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Modal
            open={secondLevelModalOpen}
            onCancel={setSecondLevelModalOpen}
            onOk={onSecondLevelSubmit}
          >
            <div
              className={styles.modalContent}
              style={{ width: '100%' }}
            >
              <Form.Item
                label="二级类别"
                name="categoryName"
                extra="*4个汉字长度"
                rules={[{ required: secondLevelModalOpen }]}
              >
                <Input
                  autoComplete="off"
                  maxLength={4}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                label="分享标题"
                name="shareTitle"
              >
                <Input
                  autoComplete="off"
                  maxLength={15}
                  showCount
                />
              </Form.Item>
              <Form.Item
                label="分享图"
                shouldUpdate
                extra="*支持JPG、PNG，大小不超过5M （标准尺寸规范：424*340）"
              >
                {({ getFieldValue, setFieldValue }) => {
                  const image = getFieldValue('image');
                  return shareImgUploadFormItem(uploadLoading, image, setFieldValue);
                }}
              </Form.Item>
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}
